export const rootRoutePath = '/';
export const loginRootRoutePath = '/log-in';
export const signUpRootRoutePath = '/sign-up';
export const activateUserRootRoutePath = '/activate-user';
export const passcodeConfirmRootRoutePath = '/confirm';
export const forgotPasswordRootRoutePath = '/forgot-password';
export const setPasswordRootRoutePath = '/set-password';

export const stakeholdersRootSegmentPath = 'stakeholders';
export const kycRootSegmentPath = 'kyc';
export const kybRootSegmentPath = 'kyb';
export const onboardingRootSegmentPath = 'onboarding';
export const cardTiersRootSegmentPath = 'card-tiers';
export const onboardingRootRoutePath = `/${onboardingRootSegmentPath}`;
export const stakeholdersRootRoutePath = `/${stakeholdersRootSegmentPath}`;
export const kycRootRoutePath = `/${kycRootSegmentPath}`;
export const kybRootRoutePath = `/${kybRootSegmentPath}`;
export const cardTiersRootRoutePath = `/${cardTiersRootSegmentPath}`;

export const accountRootRoutePath = '/account';

export const genericAddRouteSegmentPath = 'add';
export const genericEditRouteSegmentPath = 'edit';
export const settingsRouteSegmentPath = 'settings';
export const editSettingsRouteSegmentPath = `${genericEditRouteSegmentPath}-${settingsRouteSegmentPath}`;


export const accountsRouteSegmentPath = 'accounts';
export const cardsRouteSegmentPath = 'cards';
export const cardRouteSegmentPath = 'card';
export const beneficiariesRouteSegmentPath = 'beneficiaries';
export const beneficiaryRouteSegmentPath = 'beneficiary';

export const businessUsersRouteSegmentPath = 'business-users';
export const businessUserRouteSegmentPath = 'business-user';

export const transactionsRouteSegmentPath = 'transactions';
export const transactionRouteSegmentPath = 'transaction';

export const paymentsRouteSegmentPath = 'payments';
export const bankTransferRouteSegmentPath = 'bank-transfer';
export const internalTransferRouteSegmentPath = 'internal-transfer';
export const exchangeRouteSegmentPath = 'exchange';

export const firstLevelTabsSlugName = 'activeMainTabSegmentURL';
export const secondLevelTabsSlugName = 'activeSubTabSegmentURL';


export const faqRootRoutePath = '/faq';
export const attributionsRoutePath = '/attributions';
export const myProfileRootRoutePath = '/my-profile';
export const companyInfoRootRoutePath = '/company-info';
export const transactionsListRootRoutePath = `/${transactionsRouteSegmentPath}`;
export const transactionRootRoutePath = `/${transactionRouteSegmentPath}`;
export const internalTransferRootRoutePath = `/${paymentsRouteSegmentPath}/${internalTransferRouteSegmentPath}`;
export const exchangeRootRoutePath = `/${paymentsRouteSegmentPath}/${exchangeRouteSegmentPath}`;
export const paymentsRootRoutePath = `/${paymentsRouteSegmentPath}`;
export const cardsListRootRoutePath = `/${cardsRouteSegmentPath}`;
export const addCardRootRoutePath = `/${cardsRouteSegmentPath}/${genericAddRouteSegmentPath}`;
export const cardDetailsRootRoutePath = `/${cardRouteSegmentPath}`;
export const accountsListRootRoutePath = `/${accountsRouteSegmentPath}`;

export const businessUserRootRoutePath = `/${businessUserRouteSegmentPath}`;
export const businessUsersListRootRoutePath = `/${businessUsersRouteSegmentPath}`;
export const addBusinessUserRootRoutePath = `${businessUsersRouteSegmentPath}/${genericAddRouteSegmentPath}`;

export const beneficiaryRootRoutePath = `/${beneficiaryRouteSegmentPath}`;
export const beneficiariesListRootRoutePath = `/${beneficiariesRouteSegmentPath}`;
export const addBeneficiaryRootRoutePath = `/${beneficiariesRouteSegmentPath}/${genericAddRouteSegmentPath}`;

export const editBeneficiaryRootRoutePath = `/${genericEditRouteSegmentPath}/${beneficiaryRouteSegmentPath}`;
export const bankTransferRootRoutePath = `${paymentsRootRoutePath}/${bankTransferRouteSegmentPath}`;


export const publicRoutes = [
    rootRoutePath,
    loginRootRoutePath,
    passcodeConfirmRootRoutePath,
    forgotPasswordRootRoutePath,
    setPasswordRootRoutePath,
];

export const authorisedRoutesWithoutClientContext = [
    myProfileRootRoutePath,
    faqRootRoutePath,
    paymentsRouteSegmentPath,
    transactionsListRootRoutePath,
    paymentsRootRoutePath,
    firstLevelTabsSlugName,
    secondLevelTabsSlugName,
    attributionsRoutePath,
    editBeneficiaryRootRoutePath,
    beneficiaryRootRoutePath,
    beneficiariesListRootRoutePath,
    businessUserRootRoutePath,
    cardDetailsRootRoutePath,
    cardsListRootRoutePath,
    accountsListRootRoutePath,
    businessUsersListRootRoutePath,
];

